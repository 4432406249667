import {createMediaStreamTrackProcessor} from '../processor';
import {createMediaStreamTrackGenerator} from '../generator';
import {createStreamTransformer} from '../transformer';
import {stopStreamTracks} from '../utils';
import type {ProcessInputType} from '../../common/types/media';
import {PROCESSING_HEIGHT, PROCESSING_WIDTH} from '../../common/constants';
import {getImageSize} from '../../common/canvasRenderUtils';
import {assert} from '../../common/utils';

import {
    adaptInputFrameTransformer,
    nullTransformController,
} from './transformer';
import {
    toVideoElement,
    playVideo,
    createFrameCallbackRequest,
    createCanvas,
    getCanvasRenderingContext2D,
} from './utils';
import type {InputFrame} from './types';
import {AbortReason, FRAME_RATE} from './constants';

type Track = MediaStreamVideoTrack | MediaStreamVideoTrackGenerator;
interface Options {
    signal?: AbortSignal;
}
export type ProcessVideoTrack = (
    track: MediaStreamVideoTrack,
    transformers: Array<Transformer<InputFrame, InputFrame>>,
    options?: Options,
) => Promise<Track>;

/**
 * Video track processor using MediaStreamTrackProcessor API
 */
export const createVideoTrackProcessor =
    (): ProcessVideoTrack =>
    (track, transformers, {signal} = {}) => {
        if (!transformers.length) {
            return Promise.resolve(track);
        }
        const processor = createMediaStreamTrackProcessor({track});
        const trackGenerator = createMediaStreamTrackGenerator({kind: 'video'});
        let readable = processor.readable;
        transformers.forEach(transformer => {
            readable = readable.pipeThrough(
                createStreamTransformer(
                    adaptInputFrameTransformer(transformer),
                ),
                {signal},
            );
        });
        // Promise returned from `ReadableStream['pipeTo']` is only resolved
        // when the streaming is finished
        readable
            .pipeTo(trackGenerator.writable, {
                signal,
            })
            .catch(error => {
                // Ignore abort error
                if (
                    signal &&
                    !(
                        signal.aborted &&
                        (signal.reason === AbortReason.Close ||
                            // AbortSignal['reason'] is only supported from Chromium v98 or Firefox v97
                            !('reason' in AbortSignal.prototype))
                    )
                ) {
                    throw error;
                }
            });
        return Promise.resolve(trackGenerator);
    };

interface FallbackOptions {
    width?: number;
    height?: number;
    frameRate?: number;
}
/**
 * Video track processor using Canvas[captureStream] API
 */
export const createVideoTrackProcessorWithFallback =
    ({
        width = PROCESSING_WIDTH,
        height = PROCESSING_HEIGHT,
        frameRate = FRAME_RATE,
    }: FallbackOptions = {}): ProcessVideoTrack =>
    async (track, transformers, {signal} = {}) => {
        const [transformer] = transformers;
        if (!transformer?.transform) {
            return track;
        }
        assert(transformers.length <= 1, 'Multi-transformer is NOT supported');
        const outputCanvas = createCanvas(width, height);
        const ctx = getCanvasRenderingContext2D(outputCanvas, {alpha: false});
        const render = (input: ProcessInputType) => {
            assert(transformer.transform);
            return Promise.resolve(
                transformer.transform(
                    input as InputFrame,
                    nullTransformController({
                        enqueue: frame => {
                            if (!frame) {
                                return;
                            }
                            const frameSize = getImageSize(frame);
                            if (
                                frameSize.height !== outputCanvas.height ||
                                frameSize.width !== outputCanvas.width
                            ) {
                                outputCanvas.height = frameSize.height;
                                outputCanvas.width = frameSize.width;
                            }
                            ctx.drawImage(
                                frame,
                                0,
                                0,
                                outputCanvas.width,
                                outputCanvas.height,
                            );
                        },
                        terminate: () => {
                            stop();
                        },
                    }),
                ),
            );
        };
        const runner = createFrameCallbackRequest(render, frameRate);
        const videoElement = toVideoElement(
            new MediaStream([track]),
            width,
            height,
        );
        await playVideo(videoElement);
        await runner.start(videoElement as unknown as ProcessInputType);
        const stream = outputCanvas.captureStream(frameRate);
        const [trackGenerated] = stream.getVideoTracks();
        assert(trackGenerated, 'Canvas captureStream returns no video track');
        const stop = () => {
            stopStreamTracks(stream);
            runner.stop();
        };
        signal?.addEventListener('abort', stop);
        return trackGenerated;
    };
