import {ConfigManager, configHook} from '@pexip/config-manager';
import type {
    CursorCaptureConstraint,
    DisplayCaptureSurfaceType,
    IncludeExcludeConstraint,
    MediaDeviceInfoLike,
} from '@pexip/media-control';
import {
    CURSOR_CAPTURE_CONSTRAINT,
    DISPLAY_CAPTURE_SURFACE_TYPE,
    INCLUDE_EXCLUDE_CONSTRAINT,
} from '@pexip/media-control';
import {AUDIO_CONTENT_HINTS, VIDEO_CONTENT_HINTS} from '@pexip/media';
import type {AudioContentHint, VideoContentHint} from '@pexip/media';
import {currentBrowserName, isMobileDevice} from '@pexip/media-components';

import {
    BACKGROUND_BLUR_AMOUNT,
    BG_IMAGE_URL,
    CALL_TYPE,
    EDGE_BLUR_AMOUNT,
    MASK_COMBINE_RATIO,
    FOREGROUND_THRESHOLD,
    INITIAL_BANDWIDTH,
    RENDER_EFFECTS,
    PREFER_PRES_IN_MIX,
} from './constants';
import {BrowserCloseConfirmation, StepByStepCompletion} from './types';

export const shouldEnableVideoProcessing = () =>
    !isMobileDevice() &&
    (currentBrowserName === 'Chrome' ||
        currentBrowserName === 'Firefox' ||
        currentBrowserName === 'Edge Chromium') &&
    (() => {
        const WEBGL_ATTRIBUTES = {
            alpha: false,
            antialias: false,
            premultipliedAlpha: false,
            preserveDrawingBuffer: false,
            depth: false,
            stencil: false,
            failIfMajorPerformanceCaveat: true,
        };
        try {
            const canvas = document.createElement('canvas');
            return !!(
                window.WebGLRenderingContext &&
                (canvas.getContext('webgl', WEBGL_ATTRIBUTES) ||
                    canvas.getContext('experimental-webgl', WEBGL_ATTRIBUTES))
            );
        } catch (e) {
            return false;
        }
    })();

const internalConfig = {
    asd: false,
    audioInput: {} as MediaDeviceInfoLike | undefined,
    audioOutput: {} as MediaDeviceInfoLike | undefined,
    lastFallBackMsg: '',
    showLayoutChangeConfirmationModal: true,
    showSelfviewHiddenTooltip: true,
    vad: false,
    videoInput: {} as MediaDeviceInfoLike | undefined,
    stepByStepCompletion: StepByStepCompletion.Uncompleted,
};

export const defaultUserConfig = {
    audioContentHint: AUDIO_CONTENT_HINTS.Speech as AudioContentHint,
    autoGainControl: true,
    backgroundBlurAmount: BACKGROUND_BLUR_AMOUNT,
    bandwidth: INITIAL_BANDWIDTH,
    bgImageUrl: BG_IMAGE_URL,
    callType: CALL_TYPE,
    callTypeQueryParameter: '',
    curosrCapture: CURSOR_CAPTURE_CONSTRAINT.Always as CursorCaptureConstraint,
    denoise: false,
    displayName: '',
    isGuest: false,
    displaySurface:
        DISPLAY_CAPTURE_SURFACE_TYPE.Window as DisplayCaptureSurfaceType,
    echoCancellation: true,
    edgeBlurAmount: EDGE_BLUR_AMOUNT,
    fecc: false,
    maskCombineRatio: MASK_COMBINE_RATIO,
    foregroundThreshold: FOREGROUND_THRESHOLD,
    isAudioInputMuted: false,
    isUserFacing: true,
    isVideoInputMuted: false,
    noiseSuppression: true,
    presoContentHint: VIDEO_CONTENT_HINTS.Detail as VideoContentHint,
    segmentationEffects: RENDER_EFFECTS,
    showSelfView: true,
    surfaceSwitching:
        INCLUDE_EXCLUDE_CONSTRAINT.Include as IncludeExcludeConstraint,
    userBgImageAssets: [] as string[],
    videoContentHint: VIDEO_CONTENT_HINTS.NoHint as VideoContentHint,
    videoProcessingAPI: 'stream',
    videoProcessingDelegate: 'GPU' as 'CPU' | 'GPU',
    captureAudio: true,
    join: false,
    preferPresInMix: PREFER_PRES_IN_MIX,
    mirrorSelfview: true,
    enableBrowserCloseConfirmationByDefault: false,
    browserCloseConfirmation: BrowserCloseConfirmation.Unset,
    // If the user is on a touch device we want to always show UI controls by default, not auto hide them,
    // because mobile screens readers need to be able to access the controls
    alwaysDisplayUIInterfaces:
        'matchMedia' in window
            ? window.matchMedia('(pointer: coarse)').matches
            : true,
    showParticipantAuthenicatedState: false,
    disableKeyboardShortcuts: false,
};
export type DefaultUserConfig = typeof defaultUserConfig;

export const config = new ConfigManager(
    {
        ...internalConfig,
        ...defaultUserConfig,
    },
    'infinity-connect',
);

export const useConfig = configHook(config);
