import type {RenderEffects, SegmentationModel} from '@pexip/media-processor';
import {ClientCallType} from '@pexip/infinity';

import type {FeedbackConfig} from './types';

/**
 * Video Processor Params
 */
export const FRAME_RATE = 30;
export const FOREGROUND_THRESHOLD = 0.5; // Between 0 and 1
export const BACKGROUND_BLUR_AMOUNT = 30; // Integer value from 0 to 100
export const EDGE_BLUR_AMOUNT = 2; // Between 0 and 9
export const MASK_COMBINE_RATIO = 0.5;
export const PROCESSING_WIDTH = 768;
export const PROCESSING_HEIGHT = 432;
export const SETTINGS_PROCESSING_WIDTH = 512;
export const SETTINGS_PROCESSING_HEIGHT = 288;
export const RENDER_EFFECTS: RenderEffects = 'none';
export const SEGMENTATION_MODEL: SegmentationModel = 'selfie';
export const BG_IMAGE_URL = new URL('./assets/background.png', document.baseURI)
    .pathname;

/**
 * Audio Processor Params
 */
export const UPDATE_FREQUENCY_HZ = 0.5;
export const VAD_THROTTLE_MS = 3000;
export const SILENT_DETECTION_DURATION_S = 4.0;

/**
 * Fast Fourier transform
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/AnalyserNode/fftSize
 */
export const FFT_SIZE = 2048;
export const VISUALIZER_FFT_SIZE = 1024;

/**
 * Bandwidth
 */
export const INITIAL_BANDWIDTH = '';
export type StringQuadruple = [string, string, string, string];
export const BANDWIDTHS: StringQuadruple = ['576', '1264', '2464', '6144'];

/**
 * Test your microphone
 */
export const BARS_NUMBER = 20;

/**
 * Chat
 */
export const CHARACTER_LIMIT = 10_000;

/**
 * Version
 */
export const VERSION = process.env.BUILD_HASH ?? 'BUILD_HASH missing';
export const DIRTY = process.env.BUILD_DIRTY ? '*' : '';
export const APP_VERSION = process.env.APP_VERSION;
export const CLIENT_ID = `Webapp3/${APP_VERSION}+${VERSION}${DIRTY}`;
export const PLUGIN_API_VERSION =
    process.env.PLUGIN_API_VERSION ?? 'Plugin API not supported';

/**
 * Docs
 */
export const ERROR_MESSAGES_URL =
    'https://docs.pexip.com/end_user/guide_for_admins/error_messages.htm';

/** Pexip URLs
 */
export const PEXIP_HELP_PAGE_URL = 'https://help.pexip.com/';
export const TERMS_URL = 'https://www.pexip.com/terms';

/**
 * Custom branding
 */
export const SHADES = [
    50, 100, 200, 300, 400, 500, 600, 700, 800, 900, 1000,
] as const;

export const EXPRESS_PATH = 'express';
export const STEP_BY_STEP_PATH = 'step-by-step';

/**
 * Background replacement user upload
 */
export const SUPPORTED_IMAGE_FORMATS = '.png,.jpg,.jpeg,.webp,.avif';
export const MAX_ALLOWED_MB_SIZE = 3;
export const MAX_ALLOWED_SIZE = MAX_ALLOWED_MB_SIZE * 1024 * 1024;
export const RATIO_WIDTH = 16;
export const RATIO_HEIGHT = 9;

/**
 * Infinity
 */
export const CALL_TYPE = ClientCallType.AudioVideo;
export const PREFER_PRES_IN_MIX = false;

/**
 * Breakout rooms
 */
export const TRANSFER_TIMEOUT_S = 15;

export const MAX_BREAKOUT_DURATION_HRS = 24;
export const MAX_BREAKOUT_DURATION_MIN = 59;
export const DEFAULT_BREAKOUT_DURATION_HRS = 0;
export const DEFAULT_BREAKOUT_DURATION_MIN = 0;
export const MINUTE_STEP_VALUE = 5;

/**
 * Video HTML elements
 */
export const VIDEO_MEETING_EL_ID = 'video-meeting';
export const LOCAL_PRESENTATION_EL_ID = 'local-presentation';
export const REMOTE_PRESENTATION_EL_ID = 'remote-presentation';

/**
 * Margins
 */
export const MEETING_VIDEO_MARGIN = '16px';
export const MEETING_HEADER_MARGIN = '72px';

/**
 * Image Database
 */
export const IMAGE_DATABASE_NAME = 'image';
export const IMAGE_DATABASE_VERSION = 1;
export const IMAGE_OBJECTSTORE_NAME = 'backgroundReplacement';
export const IMAGE_OBJECTSTORE_KEY_PATH = 'key';
export const IMAGE_OBJECTSTORE_KEY_NAME = 'userCustomImageKey';
export const USER_CUSTOM_IMAGE_KEY = 'userCustomImage';

/**
 * Default feedback configuration
 */
export const DEFAULT_FEEDBACK_CONFIG: FeedbackConfig = {
    enable: true,
    url: 'https://www.pexip.com/feedback',
};

/**
 * Time to wait for the recovery of muted track
 */
export const TIME_WAIT_FOR_MUTED_TRACK_RECOVERY_MS = 4000;
